import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { transformImages } from 'helpers/transformImages';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';
import Img from 'gatsby-image';
import arrow from 'img/ui/arrow_white.svg';

const HeatmapAnalysis = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query HeatmapAnalysis {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "heatmaps/heatmap-analysis" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const images = transformImages(edges);
  return (
    <>
      <h2>Heatmap analytics – best practices explained</h2>
      <p>Below, we share a few tips which will help you make the most of your heatmap analytics.</p>
      <h3>Identify the pages that you’ll run the heatmap analysis on</h3>
      <p>
        You might be wondering – is there such a thing as a ‘Google Analytics heat map’? Not quite!
        However, Google Analytics is a great starting point in your heatmap endeavors, as it helps
        you decide which webpages it would be worth setting up a{' '}
        <Link href="/heatmaps/what-is-a-heat-map/" target="_blank" rel="noopener noreferrer">
          heatmap
        </Link>{' '}
        on. Among others, these can be pages that have a disturbingly low conversion rate or a
        bounce rate well above your industry standard. The general rule of thumb is to focus on
        pages with sufficient traffic to ensure that your analysis is statistically relevant.
      </p>
      <Img
        fluid={images.ganalytics.node.childImageSharp.fluid}
        alt="Google Analytics heat map – how to know which pages are worth setting up a heat map on"
        title="Heatmap analytics – best practices explained"
      />
      <h3>How to read a heat map?</h3>
      <p>
        Assuming that you’ve set up a heat map, you may now be wondering how to read it. Here’s an
        explanation of the general rules and those relevant to specific heatmap types, such as click
        and scroll maps.
      </p>
      <h4>Understanding heatmap colors and what they indicate</h4>

      <p>
        In the most simple terms,{' '}
        <Link href="/heatmaps/what-is-a-heat-map/" target="_blank" rel="noopener noreferrer">
          heat map colors
        </Link>{' '}
        indicate areas on your webpage which are “hot” (i.e., get a lot of engagement, such as
        clicks, looks, or scrolls) and those which are “cold” (get the fewest notices or interaction
        from site visitors). The “hotter” the area, the warmer the color – think of yellows,
        oranges, and reds (with the latter colors pointing to the most noticed parts of your page).{' '}
      </p>
      <p>
        Respectively, areas with few interactions will be marked in green and blue (to remember,
        it’s good to think of blue as being ice-cold).
      </p>
      <h4> Notice where people click (click map)</h4>
      <p>Using a click map can help you do the following:</p>
      <ul>
        <li>
          <strong>identify the most clickable elements on your website</strong> as well as those
          that don’t get any clicks. This will help you find the best spots for placing your CTAs
        </li>
        <li>
          <strong>determine how many clicks each CTAs gets</strong>. Does any button on the page get
          more clicks than the most important one? Or maybe there’s a banner or sidebar which
          distracts users on their way towards the purchase? A good example of such a scenario would
          be a banner for a free ebook, which distracts users from buying a paid one.
        </li>
        <li>
          <strong>spot rage clicks or error clicks</strong>. Are there any web areas or elements
          that visitors click on intensely? This might indicate visitor frustration. For example, if
          they click on a CTA, and nothing happens, they might keep clicking on it. This could tell
          you that your CTA lacks a link or that it’s broken.
        </li>
      </ul>
      <p>
        Here’s where a usability tool like LiveSession will come to the rescue, allowing you to
        easily spot customer frustration in your heatmap analysis.
      </p>
      <video width="100%" height="100%" autoPlay muted loop>
        <source
          src="https://livesession.io/static/clicks-localisation-csv-feature-news-4f3775a58470df5d8901cd53698f197a.webm"
          type="video/webm"
        />
      </video>
      <h4> Learn how far down the page people scroll (scroll map)</h4>

      <p>
        Another benefit of using heatmap analytics is assessing the relevance of your content. To
        learn if people lose interest you can turn to scroll maps – they’ll help you check how far
        down the page people scroll.{' '}
      </p>

      <p>
        It’s worth bearing in mind that just because someone stops scrolling at half the page,
        doesn’t mean they aren’t interested in your content or your offer – it’s just one of the
        possible scenarios. It might also indicate they got answers to their questions and are ready
        to convert.{' '}
      </p>
      <Img
        fluid={images.scrollmap.node.childImageSharp.fluid}
        alt="using a scroll map as part of heatmap analytics"
        title="Learn how far down the page people scroll (scroll map)"
      />
      <p>
        To find the reason why people stopped scrolling, you should ask additional questions, like
        did they click on any CTA’s? To get a detailed overview of how visitors behave on your
        website you should turn to session recordings.{' '}
      </p>
      <h4>Noticing which parts of your page users ignore (eye-tracking & click map)</h4>

      <p>
        One of the ways of creating a good user experience for site visitors is paying attention to
        the elements that are frequently ignored. You must find the reason behind any unexpected
        behavior – are there any areas that get zero clicks, or parts of the page where people don’t
        look at all?{' '}
      </p>

      <p>
        Eye-tracking is also handy in spotting eye fixation – it might tell you where to place your
        CTAs, as it shows what elements people spend the most time looking at. It can also indicate
        the same for written content – for example, something you wrote on the page is too difficult
        to understand, which is why people spend too much time looking at it.{' '}
      </p>
      <Img
        fluid={images.eye.node.childImageSharp.fluid}
        alt="while conducting your heatmap analysis don't forget about eye tracking"
        title="Noticing which parts of your page users ignore (eye-tracking & click map)"
      />

      <p>
        Bear in mind that eye-tracking is, however, a method that requires a special device, which
        means it’s mostly used in a lab environment.
      </p>
      <h2>Avoiding heat map bias – where and how to cross-refer your findings?</h2>

      <p>
        Now that you know how to read a heatmap, it’s worth addressing the issue of heatmap bias. As
        you can see from the above section, heat maps will tell you a lot about what is happening on
        your site. However, it will not always reveal why.{' '}
      </p>

      <p>
        For this reason, you should supplement your heatmap analysis findings with data from other
        tools. We particularly recommend:
      </p>
      <h3>Session recordings</h3>
      <p>
        Unlike heatmaps which show you the bulk of interactions, session recordings allow you to
        watch recordings of specific users. Let’s assume a scenario where you notice on your heatmap
        that your “add to cart” button gets a high volume of clicks, but it doesn’t translate to the
        number of people who proceed into the purchase finalization user path. To find the reason,
        you might decide to turn to session recordings. After watching, say, 10 videos of various
        user sessions you notice that an error is to blame – people click on the “add to cart”
        element on their wishlist page, but the button doesn’t work, so users simply decide to leave
        your site.
      </p>
      <Img
        fluid={images.woocommerce.node.childImageSharp.fluid}
        alt="woocommerce"
        title="Session recordings"
      />

      <p>
        Source:{' '}
        <Link
          href="https://wordpress.org/plugins/yith-woocommerce-wishlist/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          WooCommerce
        </Link>
      </p>
      <h3>Customer surveys – qualitative & quantitative</h3>
      <p>
        {' '}
        As mentioned earlier, while heatmaps can help you spot problematic pages or areas, it might
        be difficult to draw any conclusions solely based on heatmap data. If you want to understand
        the ‘why’ behind user behavior, then we recommend turning to surveys.{' '}
      </p>

      <p>
        You can include a survey directly on your page, asking ‘Did you find everything you were
        looking for’ or ‘Could you please tell us what we can improve?’. Alternatively, you could
        send out a survey to your users asking them a few questions related to the problematic areas
        you have identified with heatmaps.{' '}
      </p>

      <h3>Usability testing sessions with users</h3>

      <p>
        Sometimes, nailing down the reasons behind UX or conversion-related issues requires closer
        user investigation than any online software can give. Here’s where you can turn to{' '}
        <Link href="/usability-testing/" target="_blank" rel="noopener noreferrer">
          usability testing
        </Link>{' '}
        sessions, which let you ask a group of people to complete a task on your website (for
        example, finalizing a purchase or finding a category in the site menu). While they do so,
        you can either watch them live or even ask follow-up questions to understand what they’re
        doing. This creates a great opportunity for what many other user-related tools lack, i.e.,
        the chance to note down a decent volume of qualitative feedback.
      </p>
      <h2> Heatmap analysis – concluding thoughts</h2>
      <p>In order to make the most of your heatmap analytics, we recommend the following tips:</p>
      <ul>
        <li>
          take a look into Google Analytics to identify the pages with decent traffic, select those
          that have low conversion or those that perform well (understanding why those pages are
          successful will help you improve your poorly performing ones) and set up a heatmap on them
        </li>
        <li>
          pay attention to different colors – it’s worth placing your CTA’s in the ‘hot spots’{' '}
        </li>
        <li>notice which areas get the most clicks and how far down the page visitors scroll</li>
        <li>
          don’t ignore elements that get a lot of clicks, as they might be considered rage or error
          clicks
        </li>
      </ul>
      <p>
        Remember that in order to avoid heatmap bias, it’s worth cross-referencing your findings
        with data from other tools like user recordings or surveys. If you’re searching for a
        platform that combines session recordings and heatmaps then check out{' '}
        <Link href="/" target="_blank" rel="noopener noreferrer">
          LiveSession
        </Link>{' '}
        – we offer a free trial.{' '}
      </p>
      <div className="next-chapter">
        <Link href="/heatmaps/heat-mapping-tool/">
          <div>
            <p>Chapter 6</p>
            <p>The top benefits of using heatmaps on your website</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(HeatmapAnalysis);
